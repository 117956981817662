<template>
  <div id="wrapper">
    <bo-navbar />
    <div class="page-content">
      <bo-sidebar />
      <router-view />
    </div>
  </div>
</template>
<script>
import BoNavbar from '@/components/BoNavbar.vue'
import BoSidebar from '@/components/BoSidebar.vue'

export default {
  components: {
    BoNavbar, BoSidebar,
  }
}
</script>