<template>
  <b-navbar type="light" toggleable="md">
    <!-- Header with logos -->
    <div class="navbar-header navbar-dark d-none d-md-flex align-items-md-center">
      <b-navbar-brand class="navbar-brand-md" tag="div">
        <a href="/" class="d-inline-block">
          <b-img src="/global_assets/images/logo_light.png" />
        </a>
      </b-navbar-brand>
      <b-navbar-brand class="navbar-brand-xs" tag="div">
        <a href="/" class="d-inline-block">
          <b-img src="/global_assets/images/logo_icon_light.png" />
        </a>
      </b-navbar-brand>
    </div>
    <!-- /header with logos -->

    <!-- Mobile controls -->
    <div class="d-flex flex-1 d-md-none">
      <b-navbar-brand class="md-auto">
        <a href="/" class="d-inline-block">
          <b-img src="/global_assets/images/logo_dark.png" alt="" />
        </a>
      </b-navbar-brand>

      <b-navbar-toggle target="navbar-mobile" class="navbar-toggler">
        <i class="icon-tree5"></i>
      </b-navbar-toggle>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-mobile">
      </button>

      <button
        class="navbar-toggler sidebar-mobile-main-toggle"
        type="button"
      >
        <i class="icon-paragraph-left3"></i>
      </button>
    </div>
    <!-- /mobile controls -->

    <!-- Navbar content -->
    <b-collapse is-nav id="navbar-mobile">
      <b-navbar-nav class="align-items-center">
        <b-nav-item
          link-classes="navbar-nav-link sidebar-control sidebar-main-toggle d-none d-md-block"
          @click="toggleSidebarDesktop"
        >
          <i class="icon-paragraph-left3"></i>
        </b-nav-item>
      </b-navbar-nav>
      <b-badge
        pill
        class="ml-md-3 mr-md-auto bg-purple-400"
      >
        online
      </b-badge>
      <b-navbar-nav class="align-items-center">
      
        <b-nav-item link-classes="navbar-nav-link" :to="{name: 'AccountSettings'}" active-class="active">
          <i class="icon-cog3"></i>
          <span class="ml-2">Account Settings</span>
        </b-nav-item>
        <b-nav-item-dropdown right toggle-class="navbar-nav-link d-flex align-items-center">
          <template #button-content>
            <b-img
              src="/global_assets/images/placeholders/placeholder.jpg"
              class="mr-2"
              rounded="circle"
              height="34"
              alt=""
            />
            <span>Admin Memosys</span>
          </template>
          <b-dropdown-item>
            <i class="icon-user-plus"></i> My profile
          </b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-item :to="{name: 'AccountSettings'}">
            <i class="icon-cog5"></i> Account settings
          </b-dropdown-item>
          <b-dropdown-item @click="logout">
            <i class="icon-switch2"></i> Logout
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>
<script>
export default {
  methods: {
    toggleSidebarDesktop() {
      document.querySelector('body').classList.toggle('sidebar-xs')
    },
    logout() {
      this.$store.commit('unsetAuth')
      this.$router.push({ name: 'BoAuth' })
    }
  }
}
</script>
