<template>
  <div class="sidebar sidebar-dark sidebar-main sidebar-expand-md">
    <!-- Sidebar mobile toggler -->
    <div class="sidebar-mobile-toggler text-center">
      <a href="#" class="sidebar-mobile-main-toggle">
        <i class="icon-arrow-left8"></i>
      </a>
      Navigation
      <a href="#" class="sidebar-mobile-expand">
        <i class="icon-screen-full"></i>
        <i class="icon-screen-normal"></i>
      </a>
    </div>
    <!-- /sidebar mobile toggler -->

    <!-- Sidebar content -->
    <div class="sidebar-content">
      <!-- Main navigation -->
      <div class="card card-sidebar-mobile">
        <b-nav tag="ul" class="nav-sidebar">
          <!-- <b-nav-item :to="{ name: 'AddProject' }" active-class="active">
            <span class="text_highlight">Add New Project</span>
            <span class="bullet_act"><i class="icon-plus2 mr-0"></i></span>
          </b-nav-item> -->
          <b-nav-item :to="{ name: 'AddIssue' }" active-class="active">
            <span class="text_highlight">Tambah Topik</span>
            <span class="bullet_act"><i class="icon-plus2 mr-0"></i></span>
          </b-nav-item>
          <component
            v-for="(menu, key) in mappedMenu"
            :key="key"
            :is="defineNav(menu)"
            :item="menu"
          />
          
        </b-nav>
      </div>
      <!-- /main navigation -->
    </div>
    <!-- /sidebar content -->
  </div>
</template>
<script>
import navUtils from '@/navigation/navUtils'
import navCap from '@/components/navigation/navCap.vue'
import navGroup from '@/components/navigation/navGroup.vue'
import navLink from '@/components/navigation/navLink.vue'
import mappedMenu from '@/navigation/navMenus'

export default {
  data() {
    return {
      mappedMenu
    }
  },
  components: {
    navCap, navGroup, navLink
  },
  methods: {
    defineNav(item) {
      return navUtils.defineComp(item)
    }
  },
}
</script>
