const projectsData = {
  datas: [
    {
      name: 'Rencana Anggaran Alutista',
      keyword: ['kemilau marmer', 'jasa poles marmer', 'poles marmer jakarta selatan'],
      hashtag: ['#marmer', '#polesmarmer'],
    },
    {
      name: 'PT TMI',
      keyword: ['rancang mebel', 'jasa custom furniture', 'custom furniture jakarta selatan'],
      hashtag: ['#furniture', '#customfurniture'],
    },
  ],
}

export default projectsData