<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>
<script>
import LayoutFull from '@/layouts/LayoutFull.vue'
import LayoutEmpty from '@/layouts/LayoutEmpty.vue'

export default {
  components: {
    LayoutFull, LayoutEmpty,
  },
  computed: {
    layout() {
      return this.$route.meta.layout == 'empty' ? 'layout-empty' : 'layout-full'
    }
  }
}
</script>
