import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue } from 'bootstrap-vue'
import vSelect from 'vue-select'
import DateRangePicker from 'vue2-daterange-picker'

vSelect.props.components.default = () => ({
  OpenIndicator: {
    render: h => h('i', { class: 'icon-arrow-down22' })
  }
})

Vue.use(BootstrapVue)
Vue.use(require('vue-moment'))
Vue.component('VSelect', vSelect)
Vue.component('DateRangePicker', DateRangePicker)
Vue.config.productionTip = false

require('@/assets/scss/style.scss')

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
