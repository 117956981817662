<template>
  <li class="nav-item-header">
    <div class="text-uppercase font-size-xs line-height-xs">{{ item.cap }}</div>
    <i class="icon-menu" v-b-tooltip.right.hover="item.cap" />
  </li>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>
