import Vue from 'vue'
import VueRouter from 'vue-router'
// import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: { name: 'BoAuth' }
  },
  {
    path: '/auth/:slug?',
    name: 'BoAuth',
    component: () => import('@/views/BoAuth.vue'),
    meta: {
      layout: 'empty'
    },
  },
  {
    path: '/project-setup',
    name: 'ProjectSetup',
    component: () => import('@/views/ProjectSetup.vue'),
    meta: {
      layout: 'empty'
    }
  },
  {
    path: '/:project/dashboard',
    name: 'ProjectDashboard',
    component: () => import('@/views/project/ProjectDashboard.vue'),
    meta: {
      pageTitle: 'Dashboard'
    }
  },
  {
    path: '/:project/settings',
    name: 'ProjectSettings',
    component: () => import('@/views/project/ProjectSettings.vue'),
    meta: {
      pageTitle: 'Project Settings'
    }
  },
  {
    path: '/:project/media-monitoring/overview',
    name: 'ProjectMediaOverview',
    component: () => import('@/views/project/ProjectMediaOverview.vue'),
    meta: {
      pageTitle: 'Media Monitoring - Overview'
    }
  },
  {
    path: '/:project/media-monitoring/mention',
    name: 'ProjectMediaMention',
    component: () => import('@/views/project/ProjectMediaMention.vue'),
    meta: {
      pageTitle: 'Media Monitoring - Mentions'
    }
  },
  {
    path: '/:project/media-monitoring/analysis-report',
    name: 'ProjectMediaAnalysis',
    component: () => import('@/views/project/ProjectMediaAnalysis.vue'),
    meta: {
      pageTitle: 'Media Monitoring - Analysis & Report'
    }
  },

  {
    path: '/:project/social-media/overview',
    name: 'ProjectSocialOverview',
    component: () => import('@/views/project/ProjectSocialOverview.vue'),
    meta: {
      pageTitle: 'Social Media Monitoring - Overview'
    }
  },
  {
    path: '/:project/social-media/insights',
    name: 'ProjectSocialInsights',
    component: () => import('@/views/project/ProjectSocialInsights.vue'),
    meta: {
      pageTitle: 'Social Media Monitoring - Insights'
    }
  },

  {
    path: '/:project/customer-relationship/overview',
    name: 'ProjectCrmOverview',
    component: () => import('@/views/project/ProjectCrmOverview.vue'),
    meta: {
      pageTitle: 'Customer Relationship Management - Overview'
    }
  },
  {
    path: '/:project/customer-relationship/leads/:slug?',
    name: 'ProjectCrmLeads',
    component: () => import('@/views/project/ProjectCrmLeads.vue'),
    meta: {
      pageTitle: 'Customer Relationship Management - Leads'
    }
  },
  {
    path: '/:project/customer-relationship/activities',
    name: 'ProjectCrmActivities',
    component: () => import('@/views/project/ProjectCrmActivities.vue'),
    meta: {
      pageTitle: 'Customer Relationship Management - Activities'
    }
  },
  {
    path: '/:project/customer-relationship/report',
    name: 'ProjectCrmReport',
    component: () => import('@/views/project/ProjectCrmReport.vue'),
    meta: {
      pageTitle: 'Customer Relationship Management - Report'
    }
  },
  {
    path: '/add-project',
    name: 'AddProject',
    component: () => import('@/views/AddProject.vue'),
    meta: {
      pageTitle: ''
    }
  },
  {
    path: '/add-issue',
    name: 'AddIssue',
    component: () => import('@/views/AddIssue.vue'),
    meta: {
      pageTitle: ''
    }
  },
  {
    path: '/account-settings',
    name: 'AccountSettings',
    component: () => import('@/views/AccountSettings.vue'),
    meta: {
      pageTitle: 'Account Settings'
    }
  },
  {
    path: '/:project/project-settings',
    name: 'IssueSettings',
    component: () => import('@/views/IssueSettings.vue'),
    meta: {
      pageTitle: 'Pengaturan Topik'
    }
  },
  {
    path: '/:project/media-listening',
    name: 'MediaListening',
    component: () => import('@/views/project-media/MediaListening.vue'),
    meta: {
      pageTitle: 'Media Listening',
    }
  },
  {
    path: '/:project/analisa-sentimen',
    name: 'SentimentAnalysis',
    component: () => import('@/views/project-media/SentimentAnalysis.vue'),
    meta: {
      pageTitle: 'Analisa Sentimen',
    }
  },
  {
    path: '/:project/list-sentimen/:slug?',
    name: 'SentimentList',
    component: () => import('@/views/project-media/SentimentList.vue'),
    meta: {
      pageTitle: 'Penangkalan Sentimen',
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to, from, next) => {
//   const isLoggedIn = store.getters['authenticated']
//   if (isLoggedIn && to.name != 'BoAuth') {
//     return next({ name: 'ProjectDashboard' })
//   } else {
//     return next({name: 'BoAuth'})
//   }
// })

export default router
