<template>
  <div class="page-content">
    <div class="content-wrapper">
      <router-view />
    </div>
  </div>
</template>
<script>
export default {
  
}
</script>