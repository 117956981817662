<template>
  <li
    class="nav-item nav-item-submenu"
    :class="{'nav-item-open': subMenuOpen}"
  >
    <b-link
      class="nav-link"
      :class="{ active }"
      @click="subMenuOpen = !subMenuOpen"
    >
      <i v-if="item.icon" :class="item.icon" />
      <span>{{ item.name }}</span>
    </b-link>
    <b-link class="project-setting" :to="{ name: 'IssueSettings', params: { project: projectSlug(item.name) } }" v-if="item.isProject">
      <i class="icon-cog"></i>
    </b-link>
    <b-collapse
      tag="ul"
      class="nav nav-group-sub"
      v-model="subMenuOpen"
    >
      <component
        v-for="(child, key) in item.children"
        :key="key"
        :item="child"
        :is="defineComp(child)"
      />
    </b-collapse>
  </li>
</template>
<script>
const _ = require('lodash')

import navUtils from '@/navigation/navUtils'
import navLink from '@/components/navigation/navLink.vue'

export default {
  name: 'navGroup',
  components: {
    navLink,
  },
  data() {
    return {
      subMenuOpen: false,
    }
  },
  computed: {
    active() {
      let mappedRoutes = this.item.children.map(child => child.route)
      return mappedRoutes.includes(this.$route)
    }
  },
  props: {
    item: {
      type: Object,
      required: true,
    }
  },
  methods: {
    defineComp(item) {
      return navUtils.defineComp(item)
    },
    projectSlug(text) {
      return _.kebabCase(text)
    }
  },
}
</script>
<style lang="scss" scoped>
  span{
    display: inline-block;
    max-width: 130px;
  }
</style>
