const _ = require('lodash')
import projectsData from '../dummies/projectsData'

const proj = projectsData.datas.map(el => el.name)

let initMenu = [
  {
    cap: 'Current Project'
  }
]

let projectMenus = proj.map(el => {
  const project = _.kebabCase(el)
  return {
    name: el,
    icon: '',
    // children: [
    //   {
    //     name: 'Dashboard',
    //     icon: 'icon-home4',
    //     route: { name: 'ProjectDashboard', params: { project } }
    //   },

    //   { 
    //     name: 'Media Monitoring',
    //     icon: 'icon-stats-bars2',
    //     children: [
    //       {
    //         name: 'Overview',
    //         icon: 'icon-file-stats',
    //         route: { name: 'ProjectMediaOverview', params: { project } }
    //       },
    //       {
    //         name: 'Mention',
    //         icon: 'icon-mention',
    //         route: { name: 'ProjectMediaMention', params: { project } }
    //       },
    //       {
    //         name: 'Analysis & Report',
    //         icon: 'icon-file-presentation',
    //         route: { name: 'ProjectMediaAnalysis', params: { project } }
    //       },
    //     ]
    //   },

    //   { 
    //     name: 'Social Media Monitoring',
    //     icon: 'icon-display',
    //     children: [
    //       {
    //         name: 'Overview',
    //         icon: 'icon-file-stats',
    //         route: { name: 'ProjectSocialOverview', params: { project } }
    //       },
    //       {
    //         name: 'Insights',
    //         icon: 'icon-stats-bars3',
    //         route: { name: 'ProjectSocialInsights', params: { project } }
    //       },
    //     ]
    //   },
      
    //   { 
    //     name: 'CRM',
    //     icon: 'icon-database',
    //     children: [
    //       {
    //         name: 'Overview',
    //         icon: 'icon-file-stats',
    //         route: { name: 'ProjectCrmOverview', params: { project } }
    //       },
    //       {
    //         name: 'Leads',
    //         icon: 'icon-users2',
    //         route: { name: 'ProjectCrmLeads', params: { project } }
    //       },
    //       {
    //         name: 'Activities',
    //         icon: 'icon-database-edit2',
    //         route: { name: 'ProjectCrmActivities', params: { project } }
    //       },
    //       {
    //         name: 'Report',
    //         icon: 'icon-file-spreadsheet',
    //         route: { name: 'ProjectCrmReport', params: { project } }
    //       },
    //     ]
    //   },

    //   {
    //     name: 'Project Settings',
    //     icon: 'icon-cog3',
    //     route: { name: 'ProjectSettings', params: { project } }
    //   }
    // ],
    isProject: true,
    children: [
      {
        name: 'Dashboard',
        icon:'icon-home4',
        route: { name: 'ProjectDashboard', params: { project } }
      },
      {
        name: 'Media Listening',
        icon:'icon-stats-bars2',
        route: { name: 'MediaListening', params: { project } }
      },
      {
        name: 'Analisa',
        icon:'icon-file-stats',
        children: [
          { name: 'Sentimen', route: { name: 'SentimentAnalysis', params: { project } } },
          { name: 'Trending' },
          { name: 'Jangkauan' },
          { name: 'Profil'},
          { name: 'Situs'},
        ]
      },
      {
        name: 'Penangkalan Sentimen',
        icon:'icon-file-stats',
        children: [
          { name: 'Daftar Tangkal Sentimen', route: { name: 'SentimentList', params: { project } } },
          { name: 'Daftar Hasil Tangkal Sentimen', route: { name: 'SentimentCounterList', params: { project } }  },
        ]
      },
      {
        name: 'Ringkasan dan Chart',
      },
      {
        name: 'Laporan',
      },
    ],
  }
})

let additionalMenus = [
  { cap: 'Menu' },
  { name: 'Settings', icon: 'icon-wrench2', route: { name: 'AccountSettings' } }
]

let mappedMenu = initMenu.concat(projectMenus, additionalMenus)

export default mappedMenu