<template>
  <b-nav-item
    :to="item.route"
    active-class="active"
  >
    <i :class="item.icon" v-if="item.icon" />
    <span>{{ item.name }}</span> 
  </b-nav-item>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    }
  }
}
</script>
